<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong>Centers / Branches</strong></span>
      <v-btn color="primary" class="mr-0" @click="addUserModal = true"> Add Center</v-btn>
    </v-card-title>
    <v-row class="ml-2 mr-2  mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.center_name" label="Center Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field v-model="filter.phone_number" label="Phone" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="pl-0">
        <v-btn color="primary" class="mr-2" @click="BranchesSearchList()"> Search</v-btn>
        <v-btn color="primary"   @click="getBranchesList()">Reset</v-btn>
      </v-col>
      <!-- <v-col cols="12" md="6">
        <v-btn color="primary" class="mr-8" @click="getBranchesList()">Refresh</v-btn>
      </v-col> -->
    </v-row>

    <v-data-table :headers="headers" :items="BranchesList" :items-per-page="10"  fixed-header
      height="400" class="table-rounded">
      <template #[`item.operate`]="{item}">
        <v-btn color="primary" class="mr-3" @click="editCenterList(item)"> Edit </v-btn>
        <Editbranchess :visible="editcenter" :item="editItem" @close="editcenter = false" />
        <v-btn color="error" text @click="deleteBranche(item)"> Delete </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="DeleteModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Delete confirmation </v-card-title>
        <v-card-text> Are you sure you want to delete ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="DeleteModal = false"> Cancel </v-btn>
          <v-btn color="primary" @click="deleteBranches(thisItemId)"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addUserModal" max-width="1000">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5"><strong> Add Centers / Branches </strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="addBranch.center_name" label="Center Name*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="addBranch.address" label="Address*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field 
                  v-model="addBranch.phone_number" 
                  label="Phone No*" 
                  required
                  counter="10"
                  @keypress="onlyNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="addBranch.city" 
                  label="City*"
                  required
                  :items="roleList"
                  item-text="name"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="addBranch.area" label="Area*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="addBranch.contact_person" label="Contact Person*" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small class="ml-3">*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="clearAddHospital()"> Cancel </v-btn>
          <v-btn color="primary" @click="addHospital()" :disabled="!formIsValid"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>

  </v-card>
</template>
<script>
import Onecity from '@/service/EyeDonationCenter.service'
import BranchesSevice from '@/service/Branches.service'
import axios from 'axios'
import Editbranchess from './EditBranches.vue'
const baseUrl = "https://api.stage.ebsr.in/"
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
export default {
  components: {
    Editbranchess,
  },
  data() {
    return {
      editItem: '',
      DeleteModal: false,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      thisItemId: '',
      headers: [
        { text: 'USER ID', value: 'id', width: '150px', align: ' d-none' },
        { text: 'CENTER NAME', value: 'center_name', width: '150px' },
        { text: 'ADDRESS', value: 'address', width: '150px' },
        { text: 'PHONE NO.', value: 'phone_number', width: '150px' },
        { text: 'CITY', value: 'city', width: '200px' },
        { text: 'AREA', value: 'area', width: '100px' },
        { text: 'CONTACT PERSON', value: 'contact_person', width: '150px' },
        { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
      BranchesList: [],
      addBranch: {
        center_name: '',
        address: '',
        phone_number: '',
        city: '',
        area: '',
        contact_person: '',
      },
      editcenter: false,
      addUserModal: false,
      roleList: [{ city_id: "", name: ""}],
      filter: {
        phone_number: '',
        center_name: '',
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getBranchesList()
        this.GetCityList()
      },
      deep: true,
    },
  },

  computed: {
    formIsValid () {
      return (
        this.addBranch.center_name &&
        this.addBranch.address &&
        this.addBranch.phone_number &&
        this.addBranch.city &&
        this.addBranch.area &&
        this.addBranch.contact_person
      )
    },
  },

  async mounted() {
    this.getBranchesList()
    this.GetCityList()
  },

  methods: {

    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9 ) {
          $event.preventDefault();
      }
    },

    editCenterList(e) {
      this.editcenter = true
      this.editItem = e
    },

    deleteBranche(e) {
      //console.log("item",e)
      this.DeleteModal = true
      this.thisItemId = e.id
    },

    async GetCityList() {
      const service = new Onecity()
      let response = await service.OneCityList()
      if (response) {
        this.roleList = response.data
      } else {
        this.roleList = []
      }
    },

    async getBranchesList() {
      this.listLoading = true
      const service = new BranchesSevice()
      let response = await service.BranchesList()

      if (response) {
        this.BranchesList = response.data
        this.totallist = this.BranchesList.length
        this.filter.center_name = ''
        this.filter.phone_number = ''
      } else {
        this.BranchesList = []
        this.totallist = 0
      }
      this.listLoading = false
    },

    clearAddHospital(){
      this.addBranch.center_name = ''
      this.addBranch.address = ''
      this.addBranch.phone_number = ''
      this.addBranch.city = ''
      this.addBranch.area = ''
      this.addBranch.contact_person = ''
      this.addUserModal = false
    },

    async addHospital() {

      if(this.addBranch.phone_number.length  < 10){
        return false,
        this.snackbarText = "Phone number should be 10 character.",
        this.snackbar = true
      }
      const data = {
        center_name: this.addBranch.center_name,
        address: this.addBranch.address,
        phone_number: this.addBranch.phone_number,
        city: this.addBranch.city,
        area: this.addBranch.area,
        contact_person: this.addBranch.contact_person,
      }
      try {
        const response = await axios.post(`${baseUrl}center/add`, data, {
          headers: {
            'Cantent-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status == 200) {
          this.addUserModal = false
          this.snackbarText = response.data.message
          this.getBranchesList()
          this.addBranch.center_name = ''
          this.addBranch.address = ''
          this.addBranch.phone_number = ''
          this.addBranch.city = ''
          this.addBranch.area = ''
          this.addBranch.contact_person = ''
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async deleteBranches(item) {
      const data = {
        id: item,
      }
      try {
        const response = await axios.post(`${baseUrl}center/delete`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        console.log('response', response)
        if (response.data.status == 200) {
          this.snackbarText = response.data.message
          this.getBranchesList()
          this.DeleteModal = false
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async BranchesSearchList() {
      this.listLoading = true
      if(this.filter.center_name || this.filter.phone_number){
      const data = {
        center_name: this.filter.center_name,
        phone_number: this.filter.phone_number,
      }
      try {
        const response = await axios.post(`${baseUrl}center/search`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          this.BranchesList = response.data.data
          this.totallist = this.BranchesList.length
        } else {
          this.BranchesList = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
      }else{
        
        this.snackbarText = "Kindly Enter The Keyword"
        this.snackbar = true
        return
      }
    },

  },
}
</script>
